import React, { useState, useContext, useEffect } from "react"
import SoundContext from "../state/SoundContext"
import { Container } from "semantic-ui-react"
import ReactPlayer from "react-player"
import styles from "./index.module.scss"
import VideoModal from "../components/VideoModal"
import videoReel from "../constants/videoReel.json"
import LocalisedLink from "../components/LocalisedLink"
import work from "../constants/work.json"
import selectedWorkList from "../constants/selectedWork.json"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Helmet } from "react-helmet"
import useWindowSize from "../utilities/useWindowSize"

import itMessages from "../i18n/it.json"
import enMessages from "../i18n/en.json"

const messages = {
  it: itMessages,
  en: enMessages,
}

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

function Index(props) {
  const { path } = props
  const { locale } = props.pageContext

  const [selectedWork, setSelectedWork] = useState(null)
  const [videoPlaying1, setVideoPlaying1] = useState(false)
  const [videoPlaying2, setVideoPlaying2] = useState(false)
  const [videoPlaying3, setVideoPlaying3] = useState(false)

  const [videoPlaying4, setVideoPlaying4] = useState(false)
  const [videoPlaying5, setVideoPlaying5] = useState(false)
  const [videoPlaying6, setVideoPlaying6] = useState(false)

  const [isMuted, setIsMuted] = useContext(SoundContext)

  const size = useWindowSize()

  useEffect(() => {
    ScrollTrigger.defaults({
      // markers: true,
    })

    gsap.from(".video-reel-container", {
      scrollTrigger: {
        trigger: ".video-reel-container",
        scrub: true,
        start: "top bottom",
        end: "center center",
      },
      width: 100,
    })

    // gsap.from(".section-title-background1", {
    //   scrollTrigger: {
    //     trigger: ".section-title-background1",
    //     scrub: true,
    //     end: "bottom center",
    //   },
    //   width: "40%",
    // })

    // gsap.from(".section-title-background2", {
    //   scrollTrigger: {
    //     trigger: ".section-title-background2",
    //     scrub: true,
    //     end: "bottom center",
    //   },
    //   width: "40%",
    // })

    // gsap.from(".section-title-background3", {
    //   scrollTrigger: {
    //     trigger: ".section-title-background3",
    //     scrub: true,
    //     end: "bottom center",
    //   },
    //   width: "40%",
    // })
  }, [])

  function renderSelectedWork() {
    return selectedWorkList.map((el, i) => {
      let selectedWorkEl = work.find(workEl => workEl.name === el)
      return (
        <div key={i} className={styles.selectedWorkExternalContainer}>
          <div
            className={styles.selectedWorkContainer}
            onClick={() => setSelectedWork(selectedWorkEl)}
          >
            {size.width <= 768 ? (
              <img
                className="video-replacement"
                src={selectedWorkEl.videoUrlReplacement}
                alt="reel thumbnail"
              />
            ) : (
              <div className="video-constraints">
                <ReactPlayer
                  className="video"
                  url={selectedWorkEl.videoUrl}
                  playing
                  muted
                  width="100%"
                  height="100%"
                  loop
                />
              </div>
            )}
            <span className={styles.selectedWorkName}>
              {selectedWorkEl.name}
            </span>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <Helmet>
        <title>
          {locale === "en"
            ? "Amplitudo | Sound Design and Music Production"
            : "Amplitudo | Sound Design e Produzione Musicale"}
        </title>
        <meta
          name="description"
          content={
            locale === "en"
              ? "We are Amplitudo Sound Agency. We produce sound to enhance visuals, tell stories and portray brands’ values."
              : "Noi siamo Amplitudo Sound Agency. Creiamo suoni per completare prodotti visivi, raccontare storie e trasmettere i valori di un brand."
          }
        />
      </Helmet>

      <div className="home-page">
        <div className={styles.homeVideoReelContainer}>
          <div className={styles.homeVideoReel}>
            <ReactPlayer
              url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/preview_showreel_2021_13sec.mp4"
              playing
              muted
              loop
            />
          </div>
          <div className={styles.homeVideoReelButton}>
            <LocalisedLink
              to="/showreel"
              locale={locale}
              className={styles.textLink}
            >
              <button className="button-primary">play reel</button>
            </LocalisedLink>
          </div>
        </div>

        <div className={styles.homeCover}>
          <div className={styles.homeVideoReelButton}>
            <LocalisedLink
              to="/showreel"
              locale={locale}
              className={styles.textLink}
            >
              <button className="button-primary">play reel</button>
            </LocalisedLink>
          </div>
        </div>

        <Container>
          <div className={`${styles.weAreSection} swap-col-order`}>
            <div className="section-title-container">
              <div>
                <h1 className="section-title">{messages[locale].weAre}</h1>
                <div className="section-title-background section-title-background1"></div>
              </div>
            </div>
            <div className="section-content-container">
              <div className="section-column">
                <div className="video-fragments-container">
                  <div className="video-fragment video-one-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/puma1.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying1(true)}
                        onMouseLeave={() => setVideoPlaying1(false)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/1_puma1.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying1}
                          onEnded={() => setVideoPlaying1(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                  <div className="video-fragment video-two-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/puma2.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying2(true)}
                        onMouseLeave={() => setVideoPlaying2(false)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/1_puma2.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying2}
                          onEnded={() => setVideoPlaying2(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                  <div className="video-fragment video-three-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/puma3.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying3(true)}
                        onMouseLeave={() => setVideoPlaying3(false)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/1_puma3.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying3}
                          onEnded={() => setVideoPlaying3(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="section-column">
                <p className="section-description we-are-description">
                  {messages[locale].weAreDescription}
                </p>
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <div className={styles.selectedWorkSection}>
            <div className="section-title-container selected-work-title">
              <div>
                <h1 className="section-title">
                  {messages[locale].selectedWork}
                </h1>
                <div className="section-title-background section-title-background2"></div>
              </div>
            </div>
            <div className={styles.selectedWorkElementsContainer}>
              {renderSelectedWork()}
            </div>
            <div className={styles.allWorkButtonContainer}>
              <LocalisedLink
                to="/work"
                locale={locale}
                className={styles.textLink}
              >
                <button className="button-primary">
                  {messages[locale].allWork}
                </button>
              </LocalisedLink>
            </div>
          </div>
        </Container>

        <div className="claims-background">
          <Container>
            <h2 className="claims">
              {messages[locale].statementImportance1}
              <br />
              {messages[locale].statementImportance2}
            </h2>
          </Container>
        </div>

        <Container>
          <div className={styles.whatWeDoSection}>
            <div
              id={styles.weCreateSoundForTitleMobile}
              className="section-title-container"
            >
              <div>
                <h1 className="section-title">{messages[locale].whatWeDo}</h1>
                <div className="section-title-background section-title-background3"></div>
              </div>
            </div>

            <div className={styles.whatWeDoCategoryRow}>
              <div>
                <div id={styles.category1} className={styles.whatWeDoCategory}>
                  <span>{locale === "en" ? <>Branding</> : <>Branding</>}</span>
                </div>
                {locale === "en" ? (
                  <div className="service-descriptions">
                    Sound Identities
                    <br />
                    Sound Logos
                    <br />
                    Openings & Jingles
                    <br />
                    Consultancy & Training
                  </div>
                ) : (
                  <div className="service-descriptions">
                    Identità Sonore
                    <br />
                    Audio Loghi
                    <br />
                    Openings & Jingles
                    <br />
                    Consulenze & Training
                  </div>
                )}
              </div>

              <div>
                <div id={styles.category2} className={styles.whatWeDoCategory}>
                  <span>
                    {locale === "en" ? <>Contents</> : <>Contenuti</>}
                  </span>
                </div>
                {locale === "en" ? (
                  <div className="service-descriptions">
                    TV & Radio Ads
                    <br />
                    Web & Social Media
                    <br />
                    Film & Documentaries
                    <br />
                    Podcasts
                  </div>
                ) : (
                  <div className="service-descriptions">
                    Spot TV & Radio
                    <br />
                    Web & Social Media
                    <br />
                    Film & Documentari
                    <br />
                    Podcast
                  </div>
                )}
              </div>
            </div>

            <div
              id={styles.weCreateSoundForTitleDesktop}
              className="section-title-container"
            >
              <div>
                <h1 className="section-title">{messages[locale].whatWeDo}</h1>
                <div className="section-title-background section-title-background3"></div>
              </div>
            </div>

            <div className={styles.whatWeDoCategoryRow}>
              <div>
                <div id={styles.category3} className={styles.whatWeDoCategory}>
                  <span>{locale === "en" ? <>Products</> : <>Prodotti</>}</span>
                </div>
                {locale === "en" ? (
                  <div className="service-descriptions">
                    Sounds in-App
                    <br />
                    Interaction & UX/UI Sounds
                    <br />
                    Augmented & Virtual Reality
                    <br />
                    Design and Prototypes
                  </div>
                ) : (
                  <div className="service-descriptions">
                    Suoni in-App
                    <br />
                    Suoni di interazione & UX/UI
                    <br />
                    Realtà Aumentata & Virtuale
                    <br />
                    Design e Prototipi
                  </div>
                )}
              </div>

              <div>
                <div id={styles.category4} className={styles.whatWeDoCategory}>
                  <span>
                    {locale === "en" ? <>Experiences</> : <>Esperienze</>}
                  </span>
                </div>
                {locale === "en" ? (
                  <div className="service-descriptions">
                    DOOH Advertising
                    <br />
                    Sound Installations
                    <br />
                    Events & In-Store
                    <br />
                    Museums
                  </div>
                ) : (
                  <div className="service-descriptions">
                    DOOH Advertising
                    <br />
                    Installazioni Sonore
                    <br />
                    Eventi & In-Store
                    <br />
                    Musei
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <div className={styles.ctaSection}>
            <h2 className="claims claims-black contacts-claim">
              {locale === "en" ? (
                <span>
                  DO YOU HAVE A PROJECT IN MIND?
                  <br />
                  LET’S TALK!
                </span>
              ) : (
                <span>
                  HAI UN PROGETTO IN MENTE?
                  <br />
                  PARLIAMONE!
                </span>
              )}
            </h2>
            <div className={styles.ctaButtonContainer}>
              <LocalisedLink
                to="/contacts"
                locale={locale}
                className={styles.textLink}
              >
                <button className="button-primary">
                  {messages[locale].contactUs}
                </button>
              </LocalisedLink>
            </div>
          </div>
        </Container>
      </div>

      {/* 
      <Container>
        <div>
          <div className={styles.homeStatementFirstContainer}>
            <div className={styles.scrollDown}></div>
            <h2
              className={`${locale === "it" ? styles.statement1FirstIt : ""} ${
                styles.statement1First
              } statement-centered statement-first-line`}
            >
              {messages[locale].homeStatement1}
            </h2>
            <h2
              className={`${locale === "it" ? styles.statement1SecondIt : ""} ${
                styles.statement1Second
              } statement-centered statement-second-line`}
            >
              {messages[locale].homeStatement2}
            </h2>
            <div className={styles.statement1Spacing}></div>
            <div className={`${styles.videoContainer} video-reel-container`}>
              <img
                className="video-replacement only-mobile"
                src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/work-rectangular/zondaventi.png"
                alt="reel thumbnail"
              />
              <div className="video-constraints only-desktop">
                <ReactPlayer
                  className="video"
                  url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/reel_low_clip.mp4"
                  playing
                  muted
                  width="100%"
                  height="100%"
                  loop
                />
              </div>
              <div
                className={styles.buttonContainer}
                onClick={() => setSelectedWork(videoReel)}
              >
                <button className="button-primary">
                  {messages[locale].playReel}
                </button>
              </div>
            </div>
          </div>

          <div className={styles.selectedWorkSection}>
            <div className="section-title-container selected-work-title">
              <div>
                <h1 className="section-title">
                  {messages[locale].selectedWork}
                </h1>
                <div className="section-title-background section-title-background2"></div>
              </div>
            </div>
            <div className={styles.selectedWorkElementsContainer}>
              {renderSelectedWork()}
            </div>
            <div className={styles.allWorkButtonContainer}>
              <LocalisedLink
                to="/work"
                locale={locale}
                className={styles.textLink}
              >
                <button className="button-primary">
                  {messages[locale].allWork}
                </button>
              </LocalisedLink>
            </div>
          </div>

          <div className={styles.statementImportance}>
            <h2 className="statement-centered statement-first-line">
              {messages[locale].statementImportance1}
            </h2>
            <h2 className="statement-centered statement-second-line">
              {messages[locale].statementImportance2}
            </h2>
          </div>

          <div className={styles.whatWeDoSection}>
            <div className="section-title-container">
              <div>
                <h1 className="section-title">{messages[locale].whatWeDo}</h1>
                <div className="section-title-background section-title-background3"></div>
              </div>
            </div>
            <div className="section-content-container">
              <div className="section-column">
                <p className="section-description">
                  {messages[locale].whatWeDoDescription}
                </p>
                <div className={styles.learnMoreLink}>
                  <LocalisedLink
                    to="/services"
                    locale={locale}
                    className={styles.textLink}
                  >
                    {messages[locale].learnMore}
                  </LocalisedLink>
                </div>
              </div>
              <div className="section-column">
                <div className="video-fragments-container">
                  <div className="video-fragment video-one-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/100x100_1.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying4(true)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/BERETTA_ZUCCA_1.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying4}
                          onEnded={() => setVideoPlaying4(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                  <div className="video-fragment video-two-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/100x100_2.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying5(true)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/BERETTA_ZUCCA_2.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying5}
                          onEnded={() => setVideoPlaying5(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                  <div className="video-fragment video-three-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/100x100_3.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying6(true)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/BERETTA_ZUCCA_3.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying6}
                          onEnded={() => setVideoPlaying6(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.ctaSection}>
            <h2 className="statement-centered statement-first-line">
              {messages[locale].cta1}
            </h2>
            <h2 className="statement-centered statement-second-line">
              {messages[locale].cta2}
            </h2>
            <div className={styles.ctaButtonContainer}>
              <LocalisedLink
                to="/contacts"
                locale={locale}
                className={styles.textLink}
              >
                <button className="button-primary">
                  {messages[locale].contactUs}
                </button>
              </LocalisedLink>
            </div>
          </div>
        </div>
      </Container> 
      */}
      <VideoModal
        selectedWork={selectedWork}
        setSelectedWork={setSelectedWork}
      />
    </>
  )
}

export default Index
